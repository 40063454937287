import { handleActions } from 'redux-actions'
import { types } from './user.meta'

const initialState = {
  user: {},
  listUser: [],
}

const login = (state, action) => ({
  ...state,
  user: action.payload,
})

const editProfileSuccess = (state, action) => ({
  ...state,
  user: action.payload,
})

const logoutSuccess = (state, action) => ({
  ...state,
  user: {},
})

const addUserSuccess = (state, action) => {
  const newListUser = [...state.listUser, action.payload]
  return {
    ...state,
    listUser: newListUser,
  }
}

const getListUserSuccess = (state, action) => ({
  ...state,
  listUser: action.payload.data,
})

export default handleActions(
  {
    [types.LOGIN_SUCCESS]: login,
    [types.EDIT_PROFILE_SUCCESS]: editProfileSuccess,
    [types.LOGOUT_SUCCESS]: logoutSuccess,
    [types.ADD_USER_SUCCESS]: addUserSuccess,
    [types.GET_LIST_USER_SUCCESS]: getListUserSuccess,
  },
  initialState
)
