import { createAction } from 'redux-actions'

export const types = {
  GET_LIST_CUSTOMER_REQUEST: 'GET_LIST_CUSTOMER_REQUEST',
  GET_LIST_CUSTOMER_SUCCESS: 'GET_LIST_CUSTOMER_SUCCESS',
}

export const actionCreator = {
  getListCustomer: createAction(types.GET_LIST_CUSTOMER_REQUEST),
  getListCustomerSuccess: createAction(types.GET_LIST_CUSTOMER_SUCCESS),
}
