import { takeLatest, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  createCategoryRequest,
  getCategoryRequest,
  createProductRequest,
  getProductRequest,
  updateListCategoryRequest,
  editProductRequest,
} from 'api/apiRouter'

import { types, actionCreator } from './product.meta'

const errMessage = {
  message: 'Opp! Đã xảy ra lỗi',
  description: 'Liên hệ ban quản trị viên để biết thêm thông tin chi tiết!',
}

function* getProductMonitor(action) {
  try {
    const { body } = yield call(getProductRequest.request)
    yield put(actionCreator.getProductSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

function* getCategory(action) {
  try {
    const { body } = yield call(getCategoryRequest.request)
    yield put(actionCreator.getCategorySuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

function* createProductMonitor(action) {
  try {
    const { body } = yield call(createProductRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.createProductSuccess(body))
    notification.success({
      message: 'Tạo sản phẩm thành công',
    })
  } catch (error) {
    notification.error(errMessage)
  }
}

function* editProductMonitor(action) {
  try {
    const { body } = yield call(editProductRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.editProductSuccess(body))
    yield call(getProductMonitor)
    notification.success({
      message: 'Sửa sản phẩm thành công',
    })
  } catch (error) {
    notification.error(errMessage)
  }
}

function* createCategory(action) {
  try {
    const { body } = yield call(createCategoryRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.createCategorySuccess(body))
    notification.success({
      message: 'Tạo loại sản phẩm thành công',
    })
  } catch (error) {
    notification.error(errMessage)
  }
}

function* updateListCategory(action) {
  try {
    const { body } = yield call(updateListCategoryRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.getCategorySuccess(body))
    notification.success({
      message: 'Cập nhật thứ tự thành công',
    })
  } catch (error) {
    notification.error(errMessage)
  }
}

export default function* productSaga() {
  yield takeLatest(types.GET_PRODUCT, getProductMonitor)
  yield takeLatest(types.CREATE_PRODUCT, createProductMonitor)
  yield takeLatest(types.CREATE_CATEGORY, createCategory)
  yield takeLatest(types.GET_CATEGORY, getCategory)
  yield takeLatest(types.UPDATE_LIST_CATEGORY, updateListCategory)
  yield takeLatest(types.EDIT_PRODUCT, editProductMonitor)
}
