import { handleActions } from 'redux-actions'
import { types } from './customer.meta'

const initialState = {
  listCustomer: [],
}

const getListCustomerSuccess = (state, action) => ({
  ...state,
  listCustomer: action.payload.data,
})

export default handleActions(
  {
    [types.GET_LIST_CUSTOMER_SUCCESS]: getListCustomerSuccess,
  },
  initialState
)
