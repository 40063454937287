import React, { Component, lazy, Suspense } from 'react'
import { Router, Redirect } from '@reach/router'
import { Spin } from 'antd'
import 'antd/dist/antd.css'

const Admin = lazy(() => import('./views/admin'))

const Login = lazy(() => import('./views/Authenticate'))
// const Register = lazy(() => import('./views/Authenticate'))
const Product = lazy(() => import('./components/Product'))
const Blog = lazy(() => import('./components/Blog'))
const CreateBlog = lazy(() => import('./components/Blog/CreateBlog'))
const EditBlog = lazy(() => import('./components/Blog/EditBlog'))
const Setting = lazy(() => import('./components/Setting'))
const Customer = lazy(() => import('./components/Customer'))
const Order = lazy(() => import('./components/Order'))

class App extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <Spin
            size="large"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          />
        }
      >
        <Router>
          <Redirect from="/" to="/admin/product" />
          <Login path="login" />
          {/*<Register path="register" />*/}
          <Admin path="admin">
            <Product path="product" />
            <Blog path="blog" />
            <CreateBlog path="blog/create" />
            <EditBlog path="blog/:slug/edit" />
            <Setting path="setting" />
            <Customer path="customer" />
            <Order path="order" />
          </Admin>
        </Router>
      </Suspense>
    )
  }
}

export default App
