import { createAction } from 'redux-actions'

export const types = {
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',

  GET_CATEGORY: 'GET_CATEGORY',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',

  UPDATE_LIST_CATEGORY: 'UPDATE_LIST_CATEGORY',
}

export const actionCreator = {
  getProducts: createAction(types.GET_PRODUCT),
  getProductSuccess: createAction(types.GET_PRODUCT_SUCCESS),
  createProduct: createAction(types.CREATE_PRODUCT),
  createProductSuccess: createAction(types.CREATE_PRODUCT_SUCCESS),
  editProduct: createAction(types.EDIT_PRODUCT),
  editProductSuccess: createAction(types.EDIT_PRODUCT_SUCCESS),

  getCategory: createAction(types.GET_CATEGORY),
  getCategorySuccess: createAction(types.GET_CATEGORY_SUCCESS),
  createCategory: createAction(types.CREATE_CATEGORY),
  createCategorySuccess: createAction(types.CREATE_CATEGORY_SUCCESS),

  updateSortCategory: createAction(types.UPDATE_LIST_CATEGORY),
}
