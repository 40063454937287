import { takeLatest, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { navigate } from '@reach/router'
import {
  createBlogRequest,
  getBlogRequest,
  editBlogRequest,
} from 'api/apiRouter'

import { types, actionCreator } from './blog.meta'

const errMessage = {
  message: 'Opp! Đã xảy ra lỗi',
  description: 'Liên hệ ban quản trị viên để biết thêm thông tin chi tiết!',
}

function* getBlog(action) {
  try {
    const { body } = yield call(getBlogRequest.request)
    yield put(actionCreator.getBlogSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

function* createBlog(action) {
  try {
    const { body } = yield call(createBlogRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.createBlogSuccess(body))
    notification.success({
      message: 'Tạo blog thành công',
    })
    setTimeout(() => {
      navigate('/admin/blog')
    }, 1800)
  } catch (error) {
    notification.error(errMessage)
  }
}

function* editBlog(action) {
  try {
    const { body } = yield call(editBlogRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.editBlogSuccess(body))
    notification.success({
      message: 'Sửa blog thành công',
    })
    setTimeout(() => {
      navigate('/admin/blog')
    }, 1800)
  } catch (error) {
    notification.error(errMessage)
  }
}

export default function* userSaga() {
  yield takeLatest(types.GET_BLOG, getBlog)
  yield takeLatest(types.CREATE_BLOG, createBlog)
  yield takeLatest(types.EDIT_BLOG, editBlog)
}
