import { createAction } from 'redux-actions'

export const types = {
  GET_LIST_ORDER_REQUEST: 'GET_LIST_ORDER_REQUEST',
  GET_LIST_ORDER_SUCCESS: 'GET_LIST_ORDER_SUCCESS',

  UPDATE_STATUS_ORDER: 'UPDATE_STATUS_ORDER',
  UPDATE_STATUS_ORDER_SUCCESS: 'UPDATE_STATUS_ORDER_SUCCESS',
}

export const actionCreator = {
  getListOrder: createAction(types.GET_LIST_ORDER_REQUEST),
  getListOrderSuccess: createAction(types.GET_LIST_ORDER_SUCCESS),

  updateStatusOrder: createAction(types.UPDATE_STATUS_ORDER),
  updateStatusOrderSuccess: createAction(types.UPDATE_STATUS_ORDER_SUCCESS),
}
