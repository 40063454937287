import { handleActions } from 'redux-actions'
import { types } from './order.meta'

const initialState = {
  listOrder: [],
}

const getListOrderSuccess = (state, action) => ({
  ...state,
  listOrder: action.payload.data,
})

const updateStatusOrderSuccess = (state, action) => ({
  ...state,
})

export default handleActions(
  {
    [types.GET_LIST_ORDER_SUCCESS]: getListOrderSuccess,
    [types.UPDATE_STATUS_ORDER_SUCCESS]: updateStatusOrderSuccess,
  },
  initialState
)
