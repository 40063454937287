import { handleActions } from 'redux-actions'
import { types } from './blog.meta'

const initialState = {
  total: 0,
  blogs: [],
}

const editBlogSuccess = (state, action) => {
  const resBlog = action.payload
  state.blogs = state.blogs.map(e => (e.id === resBlog.id ? resBlog : e))
  return { ...state }
}

const createBlogSuccess = (state, action) => ({
  ...state,
})

const getBlogSuccess = (state, action) => ({
  ...state,
  blogs: action.payload.data,
})

export default handleActions(
  {
    [types.GET_BLOG_SUCCESS]: getBlogSuccess,
    [types.CREATE_BLOG_SUCCESS]: createBlogSuccess,
    [types.EDIT_BLOG_SUCCESS]: editBlogSuccess,
  },
  initialState
)
