import { buildRequest } from 'api'

//----------- User API -----------

export const loginRequest = buildRequest('/user/login', {
  method: 'POST',
})

export const editProfileRequest = buildRequest('/user', {
  method: 'PUT',
})

export const registerRequest = buildRequest('/user/register', {
  method: 'POST',
})

export const addUserRequest = buildRequest('/user/add', {
  method: 'POST',
})

export const getListUserRequest = buildRequest('/user', {
  method: 'GET',
})

//----------- Customer API -----------

export const getListCustomerRequest = buildRequest('/customer', {
  method: 'GET',
})

//---------- Order API ------------

export const getListOrderRequest = buildRequest('/cart', {
  method: 'GET',
})

export const updateStatusOrderRequest = buildRequest('/cart', {
  method: 'PUT',
})

//----------- Product API -----------

export const createProductRequest = buildRequest('/product', {
  method: 'POST',
})

export const editProductRequest = buildRequest('/product', {
  method: 'PUT',
})

export const getProductRequest = buildRequest('/product', {
  method: 'GET',
})

export const getCategoryRequest = buildRequest('/category', {
  method: 'GET',
})

export const createCategoryRequest = buildRequest('/category', {
  method: 'POST',
})

export const updateListCategoryRequest = buildRequest(
  '/category/updateSortOrder',
  {
    method: 'PUT',
  }
)

//----------- Blog API -----------

export const getBlogRequest = buildRequest('/blog/getAllForAdmin', {
  method: 'GET',
})

export const createBlogRequest = buildRequest('/blog', {
  method: 'POST',
})

export const editBlogRequest = buildRequest('/blog', {
  method: 'PUT',
})
