import { createAction } from 'redux-actions'

export const types = {
  GET_BLOG: 'GET_BLOG',
  GET_BLOG_SUCCESS: 'GET_BLOG_SUCCESS',
  CREATE_BLOG: 'CREATE_BLOG',
  CREATE_BLOG_SUCCESS: 'CREATE_BLOG_SUCCESS',
  EDIT_BLOG: 'EDIT_BLOG',
  EDIT_BLOG_SUCCESS: 'EDIT_BLOG_SUCCESS',
}

export const actionCreator = {
  getBlog: createAction(types.GET_BLOG),
  getBlogSuccess: createAction(types.GET_BLOG_SUCCESS),
  createBlog: createAction(types.CREATE_BLOG),
  createBlogSuccess: createAction(types.CREATE_BLOG_SUCCESS),
  editBlog: createAction(types.EDIT_BLOG),
  editBlogSuccess: createAction(types.EDIT_BLOG_SUCCESS),
}
