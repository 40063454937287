import { handleActions } from 'redux-actions'
import { types } from './product.meta'

const initialState = {
  listCategory: [],
  parentCategory: [],
  childrenCategory: [],
  product: {
    total: 0,
    products: [],
  },
}

const getProductSuccess = (state, action) => ({
  ...state,
  product: {
    products: action.payload.data,
  },
})

const createProductSuccess = (state, action) => {
  state.product.products.push({ ...action.payload.data })
  return {
    ...state,
  }
}

const createCategorySuccess = (state, action) => ({
  ...state,
  childrenCategory: [...state.childrenCategory, action.payload],
})

const editProductSuccess = (state, action) => {
  return {
    ...state,
  }
}

const getCategorySuccess = (state, action) => {
  state.parentCategory = []
  state.childrenCategory = []
  state.listCategory = action.payload.data
  action.payload.data.map(i => {
    if (i.parentId === null) {
      state.parentCategory.push(i)
    } else {
      state.childrenCategory.push(i)
    }
  })

  return { ...state }
}

export default handleActions(
  {
    [types.GET_PRODUCT_SUCCESS]: getProductSuccess,
    [types.CREATE_PRODUCT_SUCCESS]: createProductSuccess,
    [types.GET_CATEGORY_SUCCESS]: getCategorySuccess,
    [types.CREATE_CATEGORY_SUCCESS]: createCategorySuccess,
    [types.EDIT_PRODUCT_SUCCESS]: editProductSuccess,
  },
  initialState
)
