import { takeLatest, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { getListCustomerRequest } from 'api/apiRouter'

import { types, actionCreator } from './customer.meta'

const errMessage = {
  message: 'Opp! Đã xảy ra lỗi',
  description: 'Liên hệ ban quản trị viên để biết thêm thông tin chi tiết!',
}

function* getListCustomer(action) {
  try {
    const { body } = yield call(getListCustomerRequest.request)

    yield put(actionCreator.getListCustomerSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

export default function* userSaga() {
  yield takeLatest(types.GET_LIST_CUSTOMER_REQUEST, getListCustomer)
}
