import { takeLatest, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { getListOrderRequest, updateStatusOrderRequest } from 'api/apiRouter'

import { types, actionCreator } from './order.meta'

const errMessage = {
  message: 'Opp! Đã xảy ra lỗi',
  description: 'Liên hệ ban quản trị viên để biết thêm thông tin chi tiết!',
}

function* getOrderCustomer(action) {
  try {
    const { body } = yield call(getListOrderRequest.request)

    yield put(actionCreator.getListOrderSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

function* updateStatusOrder(action) {
  try {
    const { setVisible } = action.payload
    const { body } = yield call(updateStatusOrderRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.updateStatusOrderSuccess(body))
    notification.success({ message: 'Cập trạng thái đơn hàng thành công' })
    setVisible && setVisible(false)
    yield call(getOrderCustomer)
  } catch (error) {
    notification.error(errMessage)
  }
}

export default function* userSaga() {
  yield takeLatest(types.GET_LIST_ORDER_REQUEST, getOrderCustomer)
  yield takeLatest(types.UPDATE_STATUS_ORDER, updateStatusOrder)
}
