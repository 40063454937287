import { takeLatest, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { navigate } from '@reach/router'
import {
  loginRequest,
  editProfileRequest,
  registerRequest,
  addUserRequest,
  getListUserRequest,
} from 'api/apiRouter'

import { types, actionCreator } from './user.meta'

const errMessage = {
  message: 'Opp! Đã xảy ra lỗi',
  description: 'Liên hệ ban quản trị viên để biết thêm thông tin chi tiết!',
}

function* login(action) {
  try {
    const { body } = yield call(loginRequest.request, {
      data: action.payload,
    })
    const { user, token } = body
    if (token) {
      localStorage.setItem('bkdutk', token)
      yield put(actionCreator.loginSuccess(user))
      notification.success({
        message: 'Đăng nhập thành công',
        duration: 1,
      })
      setTimeout(() => {
        navigate('/admin/product')
      }, 1000)
    } else {
      throw Error
    }
  } catch (error) {
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data.message === 'LOGIN_FAIL'
    ) {
      return notification.error({
        message: 'Email hoặc password không đúng',
      })
    }
    notification.error(errMessage)
  }
}

function* editProfile(action) {
  try {
    const { body } = yield call(editProfileRequest.request, {
      data: action.payload,
    })
    const { user } = body
    yield put(actionCreator.editProfileSuccess(user))
    notification.success({
      message: 'Edit profile success!',
    })
  } catch (error) {
    notification.error(errMessage)
  }
}

function* register(action) {
  try {
    const { body } = yield call(registerRequest.request, {
      data: action.payload,
    })
    const { user, token } = body
    localStorage.setItem('utk', token)
    yield put(actionCreator.loginSuccess(user))
    notification.success({
      message: 'Register success!',
      duration: 1.5,
    })
    setTimeout(() => {
      navigate('/admin')
    }, 1800)
  } catch (error) {
    if (error.response && error.response.status === 409) {
      return notification.error({
        message: 'Email Already Exist',
        description: 'Your email already exist, please try another email',
      })
    }
    notification.error(errMessage)
  }
}

function* logout(action) {
  try {
    localStorage.removeItem('bkdutk')
    yield put(actionCreator.logoutSuccess())
    navigate('/login')
  } catch (error) {
    notification.error(errMessage)
  }
}

function* addUserMonitor(action) {
  try {
    const { body } = yield call(addUserRequest.request, {
      data: action.payload,
    })
    yield put(actionCreator.addUserSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

function* getListUser(action) {
  try {
    const { body } = yield call(getListUserRequest.request)
    yield put(actionCreator.getListUserSuccess(body))
  } catch (error) {
    notification.error(errMessage)
  }
}

export default function* userSaga() {
  yield takeLatest(types.EDIT_PROFILE, editProfile)
  yield takeLatest(types.LOGIN, login)
  yield takeLatest(types.REGISTER, register)
  yield takeLatest(types.ADD_USER, addUserMonitor)
  yield takeLatest(types.LOGOUT, logout)
  yield takeLatest(types.GET_LIST_USER_REQUEST, getListUser)
}
