import { createAction } from 'redux-actions'

export const types = {
  EDIT_PROFILE: 'EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REGISTER: 'REGISTER',
  ADD_USER: 'ADD_USER',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  GET_LIST_USER_REQUEST: 'GET_LIST_USER_REQUEST',
  GET_LIST_USER_SUCCESS: 'GET_LIST_USER_SUCCESS',
}

export const actionCreator = {
  editProfile: createAction(types.EDIT_PROFILE),
  editProfileSuccess: createAction(types.EDIT_PROFILE_SUCCESS),
  login: createAction(types.LOGIN),
  loginSuccess: createAction(types.LOGIN_SUCCESS),
  logout: createAction(types.LOGOUT),
  logoutSuccess: createAction(types.LOGOUT_SUCCESS),
  register: createAction(types.REGISTER),
  addUser: createAction(types.ADD_USER),
  addUserSuccess: createAction(types.ADD_USER_SUCCESS),
  getListUser: createAction(types.GET_LIST_USER_REQUEST),
  getListUserSuccess: createAction(types.GET_LIST_USER_SUCCESS),
}
