import { all, fork } from 'redux-saga/effects'
import user from './user/user.saga'
import product from './product/product.saga'
import blog from './blog/blog.saga'
import customer from './customer/customer.saga'
import order from './order/order.saga'

export default function* mainSaga() {
  yield all([fork(user)])
  yield all([fork(product)])
  yield all([fork(blog)])
  yield all([fork(customer)])
  yield all([fork(order)])
}
